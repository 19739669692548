import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useReceivePcnsList() {
    const toast = useToast();
    const records = ref(null);

    // Table Handler
    const columns = [
        { key: 'select', label: 'Check' },
        { key: 'number', label: 'Pcn Number', sortable: true },
        { key: 'received', label: 'Received', sortable: true },
        { key: 'ticketsCount', label: 'Quantity', sortable: true },
        { key: 'value', label: 'Value', sortable: true },
        { key: 'mass', label: 'Mass', sortable: true },
        'actions',
    ];

    const from = ref(0);
    const to = ref(0);
    const meta = ref({ from: 0, to: 0, total: 0 });
    const perPage = ref(10);
    const totalRecords = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [5, 10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    const locationFilter = ref(null);
    const societyFilter = ref(null);
    const marketFilter = ref(null);
    const saleFilter = ref(null);
    const locationType = ref(null);

    const selectedItems = ref([]);
    const selectAll = ref(false);

    const refetch = () => {
        records.value.refresh();
    };

    watch([locationFilter, marketFilter, saleFilter, societyFilter, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch();
    });

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-receive/list', {
                location_id: locationFilter.value,
                market_id: marketFilter.value,
                sale_id: saleFilter.value,
                society_id: societyFilter.value,
                query: searchQuery.value,
                per_page: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
            })
            .then(response => {
                const { agreements, meta } = response.data;

                meta.value = meta;
                callback(agreements);
                totalRecords.value = meta.value.total;
                from.value = meta.value.from;
                to.value = meta.value.to;
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching requisitions list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveStatusVariant = approved => {
        if (approved === true) return 'success';
        if (approved === false) return 'secondary';
        return 'warning';
    };

    const toggleSelect = (id) => {
        if (selectedItems.value.includes(id)) {
            selectedItems.value = selectedItems.value.filter(item => item !== id);
        } else {
            selectedItems.value.push(id);
        }
    };

    const toggleSelectAll = () => {
        if (selectAll.value) {
            records.value.data.forEach(item => {
                if (!selectedItems.value.includes(item.id)) {
                    selectedItems.value.push(item.id);
                }
            });
        } else {
            selectedItems.value = [];
        }
    };

    const allSelected = computed(() => {
        return records.value && records.value.data.length > 0 && selectedItems.value.length === records.value.data.length;
    });

    watch(allSelected, (newValue) => {
        selectAll.value = newValue;
    });

    watch(records, () => {
      if(records.value && records.value.data && records.value.data.length === 0){
        selectedItems.value = [];
        selectAll.value = false;
      }
      else if(allSelected.value) {
        selectAll.value = true;
      }
      else {
        selectAll.value = false;
      }
    });

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        resolveStatusVariant,
        refetch,
        locationFilter,
        marketFilter,
        saleFilter,
        societyFilter,
        selectedItems,
        selectAll,
        toggleSelect,
        toggleSelectAll,
    };
}